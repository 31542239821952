import React from "react";
import classes from "./PriceTable.module.css";
import clsx from "clsx";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import userRolesIcon from "./assets/user-roles.svg";
import consolidationIcon from "./assets/consolidation.svg";
import workspacesIcon from "./assets/workspaces.svg";
import integrationsIcon from "./assets/integrations.svg";
import supportIcon from "./assets/support.svg";

const CONTACT_URL = "https://share-eu1.hsforms.com/1BRtJRXJ9TWalGjajhtBOHQ2e98n3";

export default function CardEnterprise() {
    const { t } = useTranslation("pricing")

    return (
        <div className={clsx(classes.planCard, classes.selfStart)}>
            <div className={classes.planCardHeader}>
                <div className={classes.planCardContent}>
                    <div className={clsx(classes.planCardTitle, classes.gradientTextTertiary)}>
                        {t("premium.title")}
                    </div>

                    <div className={clsx(classes.priceSubtitle, "text-muted")}>
                        {t("premium.description")}{" "}<strong className={classes.highlight}>{t("premium.billing-limit")}</strong>
                    </div>

                    <div className="pt-4">
                        <div className={classes.textLarge}>
                            {t("premium.price")}
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.line} />

            <div className={classes.planCardContent}>
                <div className="text-muted" style={{ fontSize: 15 }}>
                    {t("premium.features-without-limits")}
                </div>

                <div className="pt-5">
                    <div className={classes.caption}>
                        {t("enterprise.includes")}
                    </div>
                    <ul className={classes.list}>
                        <li className="d-flex align-items-center gap-2">
                            <div className="flex-shrink-1">
                                <img src={userRolesIcon} alt="" className={classes.featureIcon} />
                            </div>
                            <div>
                                <div className={classes.itemTitle}>
                                    {t("premium.features.user-roles.title")}
                                </div>
                                <div className={classes.itemDescription}>
                                    {t("premium.features.user-roles.description")}
                                </div>
                            </div>
                        </li>

                        <li className="d-flex align-items-center gap-2">
                            <div className="flex-shrink-1">
                                <img src={consolidationIcon} alt="" className={classes.featureIcon} />
                            </div>
                            <div>
                                <div className={classes.itemTitle}>
                                    {t("premium.features.consolidation.title")}
                                </div>
                                <div className={classes.itemDescription}>
                                    {t("premium.features.consolidation.description")}
                                </div>
                            </div>
                        </li>

                        <li className="d-flex align-items-center gap-2">
                            <div className="flex-shrink-1">
                                <img src={workspacesIcon} alt="" className={classes.featureIcon} />
                            </div>
                            <div>
                                <div className={classes.itemTitle}>
                                    {t("premium.features.workspaces.title")}
                                </div>
                                <div className={classes.itemDescription}>
                                    {t("premium.features.workspaces.description")}
                                </div>
                            </div>
                        </li>

                        <li className="d-flex align-items-center gap-2">
                            <div className="flex-shrink-1">
                                <img src={integrationsIcon} alt="" className={classes.featureIcon} />
                            </div>
                            <div>
                                <div className={classes.itemTitle}>
                                    {t("premium.features.integrations.title")}
                                </div>
                                <div className={classes.itemDescription}>
                                    {t("premium.features.integrations.description")}
                                </div>
                            </div>
                        </li>

                        <li className="d-flex align-items-center gap-2">
                            <div className="flex-shrink-1">
                                <img src={supportIcon} alt="" className={classes.featureIcon} />
                            </div>
                            <div>
                                <div className={classes.itemTitle}>
                                    {t("premium.features.support.title")}
                                </div>
                                <div className={classes.itemDescription}>
                                    {t("premium.features.support.description")}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={classes.planCardAction}>
                <Button variant="outlined" target="_blank" className="w-100" rel="nofollow" href={CONTACT_URL} size="large">
                    {t("enterprise.contact")}
                </Button>
            </div>
        </div>
    )
}
