import React from "react";
import classes from "./PriceTable.module.css";
import Icon from "Components/Icon";
import Tooltip from "Components/Tooltip";
import iconInfinite from "./assets/icon-infinite.svg";
import { PLANS } from "Utils/constants";
import { useTranslation } from "react-i18next";
import useLocale from "Hooks/useLocale";

const PRICES = {
    BUSINESS: {
        STARTER: {
            monthly: 39,
            yearly: 33,
            yearlyFullPrice: 399,
            savings: 69,
        },
        ADVANCED: {
            monthly: 99,
            yearly: 84,
            yearlyFullPrice: 999,
            savings: 200,
        }
    }
}

function formatList(list, locale) {
    if (typeof Intl.ListFormat === 'undefined') {
        return list.join(', ');
    }

    const formatter = new Intl.ListFormat(locale, {
        style: 'long',
        type: 'conjunction',
    });
    return formatter.format(list);
}

function formatDisjunctiveList(list, locale) {
    if (typeof Intl.ListFormat === 'undefined') {
        const disjuntive = locale === "es-ES" ? 'o' : 'or';

        return list.join(` ${disjuntive} `);
    }

    const formatter = new Intl.ListFormat(locale, {
        style: 'long',
        type: 'disjunction',
    });
    return formatter.format(list);
}

export default function usePriceTableConfig() {
    const { t } = useTranslation("pricing");
    const locale = useLocale();

    const priceConfig = {
        plans: [
            {
                id: PLANS.BUSINESS_2,
                title: t("business-starter.title"),
                description: <>
                    {t("business-starter.description")}{" "}
                    <strong className={classes.highlight}>{t("business-starter.billing-limit")}</strong>
                </>,
                classes: {
                    title: classes.gradientTextPrimary,
                },
                price: PRICES.BUSINESS.STARTER,
                iconList: [{
                    iconName: "banks",
                    title: t("business-starter.features.connected-banks.title"),
                    description: (
                        <>
                            {t("business-starter.features.connected-banks.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("business-starter.features.connected-banks.description-slot-2")}</strong>
                            {" "}
                            {t("business-starter.features.connected-banks.description-slot-3")}
                        </>
                    )
                }, {
                    iconName: "dashboards",
                    title: t("business-starter.features.dashboards.title"),
                    description: t("business-starter.features.dashboards.description"),
                }, {
                    iconName: "alerts",
                    title: t("business-starter.features.alerts-reports.title"),
                    description: t("business-starter.features.alerts-reports.description"),
                }, {
                    iconName: "categories",
                    title: t("business-starter.features.categorization.title"),
                    description: t("business-starter.features.categorization.description"),
                }, {
                    iconName: "users",
                    title: t("business-starter.features.multiuser.title"),
                    description: (
                        <>
                            {t("business-starter.features.multiuser.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("business-starter.features.multiuser.description-slot-2")}</strong>
                        </>
                    )
                }, {
                    iconName: "invoices",
                    title: t("business-starter.features.invoices.title"),
                    description: (
                        <>
                            {t("business-starter.features.invoices.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("business-starter.features.invoices.description-slot-2")}</strong>
                            {" "}
                            {t("business-starter.features.invoices.description-slot-3")}
                        </>
                    ),
                }, {
                    iconName: "calendar",
                    title: t("business-starter.features.calendar.title"),
                    description: t("business-starter.features.calendar.description"),
                }, {
                    iconName: "chat",
                    title: t("business-starter.features.chat-support.title"),
                    description: t("business-starter.features.chat-support.description"),
                }, {
                    iconName: "workspaces",
                    title: t("business-starter.features.workspaces.title"),
                    description: t("business-starter.features.workspaces.description"),
                    disabled: true
                }, {
                    iconName: "forecast",
                    title: t("business-starter.features.forecasting.title"),
                    description: t("business-starter.features.forecasting.description"),
                    disabled: true
                }, {
                    iconName: "config",
                    title: t("business-starter.features.config.title"),
                    description: t("business-starter.features.config.description"),
                    disabled: true
                }]
            }, {
                id: PLANS.BUSINESS_3,
                title: t("business-advanced.title"),
                description: (<>
                    {t("business-advanced.description")}{" "}
                    <strong className={classes.highlight}>{t("business-advanced.billing-limit")}</strong>
                </>),
                classes: {
                    title: classes.gradientTextBlack,
                },
                price: PRICES.BUSINESS.ADVANCED,
                iconList: [{
                    iconName: "banks",
                    title: t("business-advanced.features.connected-banks.title"),
                    description: (
                        <>
                            {t("business-advanced.features.connected-banks.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("business-advanced.features.connected-banks.description-slot-2")}</strong>
                            {" "}
                            {t("business-advanced.features.connected-banks.description-slot-3")}
                        </>
                    ),
                }, {
                    iconName: "dashboards",
                    title: t("business-advanced.features.dashboards.title"),
                    description: t("business-advanced.features.dashboards.description"),
                }, {
                    iconName: "alerts",
                    title: t("business-advanced.features.alerts-reports.title"),
                    description: t("business-advanced.features.alerts-reports.description"),
                }, {
                    iconName: "categories",
                    title: t("business-advanced.features.categorization.title"),
                    description: t("business-advanced.features.categorization.description"),
                }, {
                    iconName: "users",
                    title: t("business-advanced.features.multiuser.title"),
                    description: (
                        <>
                            {t("business-advanced.features.multiuser.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("business-advanced.features.multiuser.description-slot-2")}</strong>
                        </>
                    ),
                }, {
                    iconName: "invoices",
                    title: t("business-advanced.features.invoices.title"),
                    description: (
                        <>
                            {t("business-advanced.features.invoices.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("business-advanced.features.invoices.description-slot-2")}</strong>
                            {" "}
                            {t("business-advanced.features.invoices.description-slot-3")}
                        </>
                    ),
                }, {
                    iconName: "calendar",
                    title: t("business-advanced.features.calendar.title"),
                    description: t("business-advanced.features.calendar.description"),
                }, {
                    iconName: "chat",
                    title: t("business-advanced.features.chat-support.title"),
                    description: t("business-advanced.features.chat-support.description"),
                }, {
                    iconName: "workspaces",
                    title: t("business-advanced.features.workspaces.title"),
                    description: (
                        <>
                            {t("business-advanced.features.workspaces.description-slot-1")}
                            {" "}
                            <strong className={classes.highlight}>{t("business-advanced.features.workspaces.description-slot-2")}</strong>
                        </>
                    ),
                }, {
                    iconName: "forecast",
                    title: t("business-advanced.features.forecasting.title"),
                    description: t("business-advanced.features.forecasting.description"),
                }, {
                    iconName: "config",
                    title: t("business-advanced.features.config.title"),
                    description: t("business-advanced.features.config.description"),
                }],
            }],
        table: {
            columns: [{
                title: <div className={classes.gradientTextPrimary}>{t("business-starter.title")}</div>,
                price: PRICES.BUSINESS.STARTER,
            }, {
                title: <div className={classes.gradientTextBlack}>{t("business-advanced.title")}</div>,
                price: PRICES.BUSINESS.ADVANCED,
            }, {
                title: <div className={classes.gradientTextTertiary}>{t("premium.title")}</div>,
                price: t("personalized"),
            }],
            rows: [{
                isDivider: true,
                title: t("cross-table.connected-banks"),
            }, {
                title: t("cross-table.syncronized-banks"),
                cells: ['5', '10', t("personalized"),]
            }, {
                title: <div className="d-flex align-items-center gap-1" key="importacion-datos-plus2">
                    <span>{t("cross-table.business-use")}</span>
                    <Tooltip label={t("cross-table.business-use-description")}>
                        <span className="cursor-help"><Icon name="circle-info" size={20} color="rgba(32,32,32, .25)" /></span>
                    </Tooltip>
                </div>,
                cells: [
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                ]
            }, {
                title: t("cross-table.billing"),
                cells: [
                    t("business-starter.billing-limit"),
                    t("business-advanced.billing-limit"),
                    t("premium.billing-limit")
                ]
            }, {
                isDivider: true,
                title: t("cross-table.categorization"),
            }, {
                title: t("cross-table.categorization-suggestions"),
                cells: [
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                title: t("cross-table.automatic-rules"),
                cells: [
                    <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                    <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                    <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />
                ]
            }, {
                isDivider: true,
                title: t("cross-table.search"),
            }, {
                title: t("cross-table.history"),
                cells: [
                    <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                    <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />,
                    <img key="infinite-bank-sync" src={iconInfinite} alt={t("cross-table.unlimited")} />
                ]
            }, {
                title: t("cross-table.export"),
                cells: [
                    formatList(['PDF', 'CSV', 'JSON'], locale),
                    formatList(['PDF', 'CSV', 'JSON'], locale),
                    formatList(['PDF', 'CSV', 'JSON'], locale)
                ]
            }, {
                title: t("cross-table.import"),
                cells: [
                    t("cross-table.self-service"),
                    t("cross-table.self-service"),
                    <div className="d-flex align-items-center gap-1 justify-content-center" key="importacion-datos-plus">
                        <span>{t("cross-table.ocr")}</span>
                        <Tooltip label={t("cross-table.ocr-description")}>
                            <span className="cursor-help"><Icon name="circle-info" size={20} color="rgba(32,32,32, .25)" /></span>
                        </Tooltip>
                    </div>,
                ]
            }, {
                isDivider: true,
                title: t("cross-table.cash-flow"),
            }, {
                title: t("cross-table.dashboards"),
                cells: [
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                title: t("cross-table.reports-alerts"),
                cells: [
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                title: t("cross-table.forecasting"),
                cells: [
                    <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                isDivider: true,
                title: t("cross-table.concilation"),
            }, {
                title: t("cross-table.invoices"),
                cells: [
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                title: t("cross-table.contacts"),
                cells: [
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                title: t("cross-table.invoices-quarter"),
                cells: [
                    "250",
                    "1000",
                    t("personalized")
                ]
            }, {
                isDivider: true,
                title: t("cross-table.integraciones"),
            }, {
                title: t("cross-table.messaging-channels"),
                cells: [
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                title: t("cross-table.erps"),
                cells: [
                    <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                isDivider: true,
                title: t("cross-table.multi-users"),
            }, {
                title: t("cross-table.users"),
                cells: ["3", "5", t("personalized")]
            }, {
                title: t("cross-table.user-roles"),
                cells: [
                    <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                    <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />
                ]
            }, {
                title: t("cross-table.workspaces"),
                cells: ["1", "3", t("personalized")]
            }, {
                title: t("cross-table.number-of-companies"),
                cells: ["1", "1", t("personalized")]
            }, {
                isDivider: true,
                title: t("cross-table.support"),
            }, {
                title: t("cross-table.account-manager"),
                cells: [
                    <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                    <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                    <Icon key="onboarding-icon-check" name="check" color="primary" size={14} />,
                ]
            }, {
                title: t("cross-table.onboarding"),
                cells: [
                    <Icon key="onboarding-icon-close-2" name="close" color="#cccccc" size={14} />,
                    t("cross-table.yes-annual-plan"),
                    t("cross-table.yes-annual-plan")
                ]
            }, {
                title: t("cross-table.contact-methods"),
                cells: [
                    formatDisjunctiveList(["Chat", "Email"], locale),
                    formatDisjunctiveList(["Chat", "Email"], locale),
                    formatDisjunctiveList(["Chat", "Email", t("cross-table.phone")], locale),
                ]
            }]
        }
    }

    return priceConfig
}