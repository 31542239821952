import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./ProviderSelector.module.css";
import Switch from "Components/Switch";
import Select from "Components/Select";
import TextField from "Components/TextField";
import Button from "Components/Button";
import { updateProviderConnector } from "Services/providersConnections";
import { useQueryClient } from "@tanstack/react-query";
import { useErrors } from "Components/ErrorsProvider";
import { useTranslation } from "react-i18next";

const statusOptions = [{
    value: "healthy",
    label: "Healthy",
}, {
    value: "some_problems",
    label: "Some problems",
}, {
    value: "many_problems",
    label: "Many problems",
}, {
    value: "broken",
    label: "Broken",
}];

export default function ProviderConnectorsSelectorSupportForm(props) {
    const { connector, onCancel } = props;

    const [statusMessage, setStatusMessage] = useState(connector.health_status_message ?? "");
    const [enabled, setEnabled] = useState(connector.is_enabled);
    const [status, setStatus] = useState(connector.health_status);
    const [recommended, setRecommended] = useState(connector.is_recommended);
    const [isBusiness, setIsBusiness] = useState(connector.is_business);

    const [submitting, setSubmitting] = useState(false);
    const { t } = useTranslation("providers");

    const queryClient = useQueryClient();
    const { showUnknownError } = useErrors();

    const handleSubmit = async () => {
        setSubmitting(true);
        const data = {
            health_status_message: statusMessage.trim() || null,
            is_enabled: enabled,
            is_recommended: recommended,
            health_status: status,
            is_business: isBusiness
        }

        try {
            await updateProviderConnector(connector.id, data);
            await queryClient.invalidateQueries(["providers"]);
            onCancel();
        } catch (error) {
            showUnknownError(error);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <div className={classes.supportForm}>
            <div className="d-grid gap-3">
                <div className="d-flex align-items-center gap-2 py-3">
                    <img src={connector.logo_url} alt={connector.name} style={{ height: "4rem" }} />
                    <div>
                        <h4 className="fw-500 m-0">{connector.name}</h4>
                        <div className="text-small text-muted">{`${connector.service} - ${connector.service_provider_id}`}</div>
                    </div>
                </div>
                <div>
                    <label className="d-inline-flex gap-2 align-items-center">
                        <Switch
                            checked={recommended}
                            onChange={(e) => setRecommended(e.target.checked)}
                        />
                        {t("recommended")}

                    </label>
                </div>
                <div>
                    <label className="d-inline-flex gap-2 align-items-center">
                        <Switch
                            checked={isBusiness}
                            onChange={(e) => setIsBusiness(e.target.checked)}
                        />
                        {t("business-connector")}
                    </label>
                </div>

                <div>
                    <label className="d-inline-flex gap-2 align-items-center">
                        <Switch
                            checked={enabled}
                            onChange={(e) => setEnabled(e.target.checked)}
                        />
                        {t("enabled-connector")}
                    </label>
                </div>

                <div>
                    <Select
                        label="Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        {statusOptions.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </Select>
                </div>


                <TextField
                    value={statusMessage}
                    onChange={(e) => setStatusMessage(e.target.value)}
                    label={t("status-message")}
                />

                <div className="text-center">
                    <Button primary isBusy={submitting} onClick={handleSubmit}>
                        {t("save")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

ProviderConnectorsSelectorSupportForm.propTypes = {
    connector: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
} 