import React, { useState } from "react";
import ProviderConnectionCreate from "Sections/ProviderConnectionCreate";
import propTypes from "prop-types";
import Dialog from "Components/Dialog";
import { useTranslation } from "react-i18next";
import useProvidersConnections from "Hooks/useProvidersConnections";
import Alert from "Components/Alert";
import useMediaQuery from "Hooks/useMediaQuery";

export default function BannerAddProviderOnboarding({ className }) {
    const [open, setOpen] = useState(false);
    const { fakeProviders } = useProvidersConnections();
    const isDesktop = useMediaQuery("(min-width: 768px)");

    const { t } = useTranslation("onboarding");
    if (fakeProviders.length === 0) return null;

    const closeModal = () => setOpen(false)

    return (
        <>
            <Dialog
                open={open}
                onClose={closeModal}
                size="sm"
                keepSupportChat={isDesktop}
            >
                <ProviderConnectionCreate
                    onConnect={closeModal}
                    onCancel={closeModal}
                />
            </Dialog>

            <Alert
                className={className}
                title={t("this-is-fictional-data")}
                description={t("add-your-first-bank-to-see-real-data")}
                variant="warning"
                cta={{
                    label: t("add-bank"),
                    onClick: () => setOpen(true)
                }}
            />
        </>
    )
}

BannerAddProviderOnboarding.propTypes = {
    className: propTypes.string,
};