import banks from "./assets/banks.svg";
import banksDisabled from "./assets/banks-disabled.svg";
import dashboards from "./assets/dashboards.svg";
import dashboardsDisabled from "./assets/dashboards-disabled.svg";
import alerts from "./assets/alerts.svg";
import alertsDisabled from "./assets/alerts-disabled.svg";
import categories from "./assets/categories.svg";
import categoriesDisabled from "./assets/categories-disabled.svg";
import invoices from "./assets/invoices.svg";
import invoicesDisabled from "./assets/invoices-disabled.svg";
import forecast from "./assets/forecast.svg";
import forecastDisabled from "./assets/forecast-disabled.svg";
import workspaces from "./assets/workspaces.svg";
import workspacesDisabled from "./assets/workspaces-disabled.svg";
import support from "./assets/support.svg";
import supportDisabled from "./assets/support-disabled.svg";
import users from "./assets/users.svg";
import usersDisabled from "./assets/users-disabled.svg";
import integrations from "./assets/integrations.svg";
import integrationsDisabled from "./assets/integrations-disabled.svg";
import calendar from "./assets/calendar.svg";
import chat from "./assets/chat.svg";
import config from "./assets/config.svg";
import configDisabled from "./assets/config-disabled.svg";

export const PricePlanTableIcons = {
    banks: {
        default: banks,
        disabled: banksDisabled,
    },
    dashboards: {
        default: dashboards,
        disabled: dashboardsDisabled,
    },
    alerts: {
        default: alerts,
        disabled: alertsDisabled,
    },
    categories: {
        default: categories,
        disabled: categoriesDisabled,
    },
    invoices: {
        default: invoices,
        disabled: invoicesDisabled,
    },
    forecast: {
        default: forecast,
        disabled: forecastDisabled,
    },
    workspaces: {
        default: workspaces,
        disabled: workspacesDisabled,
    },
    support: {
        default: support,
        disabled: supportDisabled,
    },
    users: {
        default: users,
        disabled: usersDisabled,
    },
    integrations: {
        default: integrations,
        disabled: integrationsDisabled,
    },
    calendar: {
        default: calendar,
        disabled: calendar,
    },
    chat: {
        default: chat,
        disabled: chat,
    },
    config: {
        default: config,
        disabled: configDisabled,
    }
};
