import React from "react";
import PropTypes from "prop-types";
import Dialog from "Components/Dialog";
import Button from "Components/Button";
import upgradeIconImage from "assets/upgrade-icon@2x.png";
import { useTranslation } from "react-i18next";

export default function ModalPaidSubscriptionRequired({ open, onClose, showPlansAndPricing }) {

    const { t } = useTranslation("common");

    const handleClick = () => {
        showPlansAndPricing();
        onClose();
    }

    return (
        <Dialog size="sm" open={open} onClose={onClose}>
            <div className="text-center">
                <div className="mb-4">
                    <img src={upgradeIconImage} width={84} height={84} alt="" className="mb-3" />
                    <h2 className="mb-2">
                        {t("modal-limit-reached-title")}
                    </h2>
                    <p className="text-muted">
                        {t("modal-limit-reached-description")}
                    </p>
                </div>
                <Button primary size="large" onClick={handleClick}>
                    {t("see-plans-and-prices")}
                </Button>
            </div>
        </Dialog>
    )
}

ModalPaidSubscriptionRequired.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    showPlansAndPricing: PropTypes.func.isRequired,
};