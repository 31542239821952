
import PropTypes from "prop-types";
import React from "react";

const ChevronLeft = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M13.857 16.977a1 1 0 0 0 0-1.41l-3.54-3.59 3.54-3.54a1 1 0 0 0-1.096-1.628 1 1 0 0 0-.324.218l-4.24 4.24a1.003 1.003 0 0 0 0 1.42l4.24 4.29a1.001 1.001 0 0 0 1.42 0Z" fill={color}/>

    </svg>
);

export default ChevronLeft;

ChevronLeft.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
