import React, { useState } from "react";
import classes from "./PriceTable.module.css";
import clsx from "clsx";
import PricePlanTable from "./PricePlanTable";
import Tabs, { Tab } from "Components/Tabs";
import Icon from "Components/Icon";
import TextButton from "Components/TextButton";
import Collapse from "Components/Collapse";
import ComparativeTable from "./ComparativeTable";
import useProjectSelected from "Hooks/useProjectSelected";
import CardEnterprise from "./CardEnterprise";
import { useTranslation } from "react-i18next";
import usePriceTableConfig from "./usePriceTableConfig";
import SketchyArrow from "./SketchyArrow";

export default function PriceTable() {
    const [tab, setTab] = useState("monthly")
    const [showComparative, setShowComparative] = useState(false)
    const { project } = useProjectSelected();
    const { plans, table } = usePriceTableConfig();

    const { t } = useTranslation("pricing");

    return (
        <>
            <section className={classes.section}>
                <div className="text-center">
                    <h1 className={classes.title}>
                        {t("title")}
                    </h1>
                    <p className={classes.description}>
                        {t("description")}
                    </p>
                </div>

                <div className={classes.tabsContainer}>
                    <div className={classes.relative}>
                        <Tabs value={tab} onChange={setTab}>
                            <Tab className={classes.planTab} value="monthly">
                                {t("monthly")}
                            </Tab>
                            <Tab className={classes.planTab} value="yearly">
                                {t("yearly")}
                            </Tab>
                        </Tabs>

                        <div className={clsx(classes.offer, tab === "yearly" && classes.actived)}>
                            <span>{t("2-months-free")}</span>
                            <SketchyArrow className={classes.offerArrow} />
                        </div>
                    </div>
                </div>

                <div className={classes.grid}>
                    {plans.map(item => (
                        <PricePlanTable
                            key={item.title}
                            {...item}
                            currentPlan={project?.subscription?.plan === item.id}
                            periodicity={tab}
                        />
                    ))}

                    <CardEnterprise />
                </div>
            </section>

            <section className={classes.section}>
                <div className="text-center py-3">
                    <TextButton className="d-inline-flex flex-column" onClick={() => setShowComparative(!showComparative)}>
                        <div>
                            {showComparative ? t("hide") : t("compare-plans")}
                        </div>
                        <div>
                            <Icon name="chevron-down" color="currentColor" size={28} className={clsx({ [classes.rotate]: showComparative })} />
                        </div>
                    </TextButton>
                </div>
                <Collapse in={showComparative} timeout="auto">
                    <ComparativeTable
                        title={t("comparative-table.title")}
                        description={t("comparative-table.description")}
                        columns={table.columns}
                        rows={table.rows}
                        periodicity={tab}
                    />
                </Collapse>
            </section>

        </>
    )
}
