
import { useMemo, useState, useLayoutEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getReportsConnections, sortReportsConnections, editReportConnection } from "Services/reportsConnections";
import useIntegrations from "./useIntegrations";
import useProvidersConnections from "./useProvidersConnections";
import { trackError } from "Utils/errorMonitoring";

export default function useReportsConnections() {
    const { data = [], isLoading, error, isFetching } = useQuery(["reports-connections"], getReportsConnections, {
        onError: error => trackError(error),
    });
    const { integrations, isLoading: isLoadingIntegrations } = useIntegrations();
    const { products, isLoading: isLoadingProvidersConnections } = useProvidersConnections();
    const [reportsConnections, setReportsConnections] = useState(() => data);
    const queryClient = useQueryClient();

    const visibleProducts = useMemo(() => products.filter((product) => !product.is_hidden), [products]);

    const populatedReportsConnections = useMemo(() => {
        return reportsConnections.map((reportConnection) => {
            return {
                ...reportConnection,
                providersConnectionsProducts: reportConnection.providers_connections_products_ids.map((providerConnectionProductId) => {
                    return visibleProducts.find((product) => product.id === providerConnectionProductId);
                }).filter(Boolean),
                channelsConnections: reportConnection.channels_connections.map((channelWithTitle) => {
                    const channelFound = integrations.find((channelConnection) => channelConnection.id === channelWithTitle.id);
                    if (!channelFound) return null;

                    return {
                        ...channelFound,
                        ...channelWithTitle
                    }
                }).filter(Boolean)
            }
        });
    }, [reportsConnections, integrations, visibleProducts]);

    useLayoutEffect(() => {
        if (isLoading || error) return;

        setReportsConnections(data);
    }, [data, isLoading, error]);

    const { mutate: sort } = useMutation(sortReportsConnections, {
        onMutate: (ids) => {
            const newReportsConnections = ids
                .map(id => reportsConnections.find(item => item.id === id))
                .filter(Boolean)
                .map((item, index) => ({ ...item, order: index + 1 }));

            setReportsConnections(newReportsConnections);
            queryClient.setQueryData(["reports-connections"], newReportsConnections);
        }
    });

    const { mutate: edit } = useMutation(editReportConnection, {
        onMutate: async (reportConnection) => {
            await queryClient.cancelQueries(["reports-connections"]);

            const previousReportsConnections = queryClient.getQueryData(["reports-connections"]);
            queryClient.setQueryData(["reports-connections"], previousReportsConnections.map(item => item.id === reportConnection.id ? reportConnection : item));

            return previousReportsConnections;
        },
        onError: (err, newReportConnection, previousReportsConnections) => {
            queryClient.setQueryData(["reports-connections"], previousReportsConnections);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["reports-connections"]);
        }
    })

    return {
        isLoading: isLoading || isLoadingIntegrations || isLoadingProvidersConnections,
        reportsConnections: populatedReportsConnections,
        sort,
        edit,
        isFetching
    }
}