import PropTypes from "prop-types";
import React, { useState } from "react";
import ProviderConnectionConnect from "Sections/ProviderConnectionConnect";
import { useUser } from "Components/UserProvider/useUser";
import Tabs, { Tab } from 'Components/Tabs';
import ProviderConnectorsSelectorSupportForm from "./ProviderConnectorsSelectorSupportForm";
import { useTranslation } from "react-i18next";

const TABS = {
    support: 'support',
    connect: 'connect',
};

export default function ProviderConnectionsConnector(props) {
    const { onConnect, connector, onError, onCancel } = props;

    const { user } = useUser();
    const [toolTab, setToolTab] = useState(TABS.support);
    const { t } = useTranslation("providers");

    if (user.is_support) {
        return (
            <div>
                <div className="d-flex justify-content-center">
                    <Tabs value={toolTab} onChange={setToolTab}>
                        <Tab value={TABS.support}>
                            {t("support")}
                        </Tab>
                        <Tab value={TABS.connect}>
                            {t("connect")}
                        </Tab>
                    </Tabs>
                </div>
                <div style={{ minHeight: 490 }}>
                    {toolTab === TABS.support && (
                        <ProviderConnectorsSelectorSupportForm
                            connector={connector}
                            onCancel={onCancel}
                        />
                    )}

                    {toolTab === TABS.connect && (
                        <ProviderConnectionConnect
                            connector={connector}
                            providerConnectionId={null}
                            onConnect={onConnect}
                            onError={onError}
                            onCancel={onCancel}
                        />
                    )}
                </div>
            </div>
        )
    }

    return (
        <ProviderConnectionConnect
            connector={connector}
            providerConnectionId={null}
            onConnect={onConnect}
            onError={onError}
            onCancel={onCancel}
        />
    );
}

ProviderConnectionsConnector.propTypes = {
    onConnect: PropTypes.func,
    connector: PropTypes.object,
    onError: PropTypes.func,
    onCancel: PropTypes.func,
};
