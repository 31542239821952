import React from "react";
import FormProvider, { RHFSelect, RHFTextField } from 'Components/HookForm';
import { useForm } from 'react-hook-form';
import Button from "Components/Button";
import PropTypes from "prop-types";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { CONNECTOR_SERVICES } from "Utils/constants";

const normalizeString = (string) => string.replace(/[^a-z0-9]/gi, '_').toLowerCase();

export default function ProviderConnectionConnectWithCredentialsLogin(props) {
    const { onSubmit, serviceId, fieldsNames, fieldsDescriptions, fieldsTypes, documentTypes, processing } = props;
    const normalizedFieldsNames = fieldsNames.map((name) => normalizeString(name));
    const service = CONNECTOR_SERVICES[serviceId];

    const { t } = useTranslation("common");

    const fields = fieldsNames.map((name, index) => ({
        label: name,
        name: normalizedFieldsNames[index],
        description: fieldsDescriptions[index],
        type: fieldsTypes[index],
    }));

    const defaultValues = {
        ...documentTypes && ({
            document_type: documentTypes[0],
        }),
        ...normalizedFieldsNames.reduce((acc, name) => {
            acc[name] = "";
            return acc;
        }, {}),
    }

    const schema = yup.object().shape({
        ...documentTypes && ({
            document_type: yup.string().required(t("provider-connections.field-required")),
        }),
        ...normalizedFieldsNames.reduce((acc, name) => {
            acc[name] = yup.string();
            return acc;
        }, {}),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const { handleSubmit } = methods;

    const onSubmitForm = (data) => {
        const { document_type, ...credentialsData } = data;

        const credentials = Object.values(credentialsData);

        onSubmit({
            documentType: documentTypes ? documentTypes.indexOf(document_type) : null,
            credentials
        });
    }

    const documentTypesOptions = documentTypes?.map(documentType => ({
        id: documentType,
        label: documentType,
    }));

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitForm)}>
            <div className="d-grid gap-4 text-start">
                {documentTypes && (
                    <RHFSelect
                        name="document_type"
                        label={t("provider-connections.document-type")}
                        options={documentTypesOptions}
                    />
                )}

                {fields.map((field, index) => (
                    <RHFTextField
                        key={index}
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        helperText={`${t("provider-connections.enter-your")} ${field.label.toLocaleLowerCase()}`}
                        inputProps={{
                            autoComplete: 'new-password',
                        }}
                    />
                ))}
            </div>

            <div className="pt-4 text-center">
                <Button size="large" primary type="submit" isBusy={processing}>
                    <img alt="" src="/img/icon-padlock.svg" />
                    <span className={"ms-2"}>
                        {t("provider-connections.connect")}
                    </span>
                </Button>

                {service && (
                    <div className="d-flex align-items-center justify-content-center mt-4 small">
                        <img alt="" src="/img/icon-padlock-black.svg" height={18} />
                        <span className="ms-2">{t("provider-connections.secure-connection-via")} {" "} <a href={service.url} target="_blank" rel="noreferrer">{service.name}</a></span>
                    </div>
                )}
            </div>
        </FormProvider>
    )
}


ProviderConnectionConnectWithCredentialsLogin.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fieldsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fieldsTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    documentTypes: PropTypes.arrayOf(PropTypes.string),
    serviceId: PropTypes.string,
    processing: PropTypes.bool,
}