import React from "react";
import PropTypes from "prop-types";

export default function SketchyArrow({ className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="13"
            fill="none"
            viewBox="0 0 26 13"
            className={className}
        >
            <path
                fill="currentColor"
                d="M25.15 1.475C20.628 9.29 10.573 11.928 2.69 7.368l1.286 4.721a.72.72 0 0 1-.522.852.735.735 0 0 1-.884-.484L.822 6.042a.72.72 0 0 1 .512-.888l6.43-1.693a.736.736 0 0 1 .895.517.72.72 0 0 1-.512.888L3.414 6.112c7.183 4.157 16.348 1.754 20.47-5.368a.73.73 0 0 1 .996-.262c.35.202.47.646.27.993"
            />
        </svg>
    )
}

SketchyArrow.propTypes = {
    className: PropTypes.string,
};