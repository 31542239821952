
import PropTypes from "prop-types";
import React from "react";

const Grid = ({ color, size, ...props }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} {...props}>
        
	<rect height="4" width="4" fill={color} rx=".5" y="12"/>
	<rect height="4" width="4" fill={color} rx=".5" y="6"/>
	<rect height="4" width="4" fill={color} rx=".5"/>
	<rect height="4" width="4" fill={color} rx=".5" x="6" y="12"/>
	<rect height="4" width="4" fill={color} rx=".5" x="6" y="6"/>
	<rect height="4" width="4" fill={color} rx=".5" x="6"/>
	<rect height="4" width="4" fill={color} rx=".5" x="12" y="12"/>
	<rect height="4" width="4" fill={color} rx=".5" x="12" y="6"/>
	<rect height="4" width="4" fill={color} rx=".5" x="12"/>

    </svg>
);

export default Grid;

Grid.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
