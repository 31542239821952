import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-utilities.min.css';
import 'Utils/polyfills';
import './index.css';

import App from './App';
import React from 'react';
import { COGNITO_POOL_ID, COGNITO_CLIENT_ID } from './config';
import { QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify';
import queryClient from 'Utils/queryClient';
import * as errorMonitoring from 'Utils/errorMonitoring';
import { createRoot } from 'react-dom/client';
import "./i18n";

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: COGNITO_POOL_ID,
            userPoolClientId: COGNITO_CLIENT_ID,
        },
    }
});

errorMonitoring.init();

if (process.env.REACT_APP_MSW === 'true') {
    const { worker } = require('./mocks/browser')
    worker.start()
}

const root = createRoot(document.getElementById('root'))
root.render(
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>
)