import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./translations/en.json";
import esTranslation from "./translations/es.json";
import { IS_DEVELOPMENT } from "config";

i18next
    .use(initReactI18next)
    .init({
        debug: IS_DEVELOPMENT,
        fallbackLng: "ES",
        resources: {
            EN: enTranslation,
            ES: esTranslation
        }
    });

i18next.on("languageChanged", (lng) => {
    document.documentElement.lang = lng.toLowerCase();
});