import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { RHFSelect, RHFTextField } from 'Components/HookForm';
import { useWatch, useFormContext } from 'react-hook-form';
import classes from "./CreateManualProviderConnection.module.css";
import Icon from "Components/Icon";
import useCurrencies from "Hooks/useCurrencies";
import IconButton from "Components/IconButton";
import Skeleton from "Components/Skeleton";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export default function CreateManualProviderConnectionRow(props) {
    const { index, onRemove } = props;
    const [isEditing, setIsEditing] = useState(true);
    const { currencies, isLoading } = useCurrencies();
    const currenciesOptions = useMemo(() => currencies.map(item => ({ id: item.code, label: `(${item.code}) ${item.name}` })), [currencies]);

    const { t } = useTranslation("common");
    const { control, setError, clearErrors, formState: { errors } } = useFormContext();

    const type = useWatch({
        name: `products[${index}].type`,
        control,
    });

    const currency = useWatch({
        name: `products[${index}].currency`,
        control,
    });

    const name = useWatch({
        name: `products[${index}].name`,
        control,
    });

    const handleSubmit = () => {
        if (!name) {
            setError(`products[${index}].name`, {
                type: "required",
                message: t("manual-bank.name-is-required")
            });
            return;
        }

        clearErrors(`products[${index}].name`);
        setIsEditing(false);
    }

    const productsOptions = [{
        label: t("cash"),
        id: "cash",
    }]

    const typeLabel = productsOptions.find(item => item.id === type)?.label ?? "";
    const currencyLabel = currenciesOptions.find(item => item.id === currency)?.label ?? "";

    const error = errors?.products?.[index]?.name?.message;

    return (
        <div className={clsx(classes.row, isEditing && classes.editing)}>
            {isEditing && (
                <>
                    <RHFTextField
                        name={`products[${index}].name`}
                        label={t("manual-bank.product-name")}
                        size="small"
                    />

                    <RHFSelect
                        name={`products[${index}].type`}
                        options={productsOptions}
                        size="small"
                    />

                    {isLoading
                        ? <Skeleton variant="rounded" height={40} />
                        : (
                            <RHFSelect
                                name={`products[${index}].currency`}
                                options={currenciesOptions}
                                size="small"
                            />
                        )
                    }
                    <div className={classes.iconCell}>
                        <IconButton onClick={handleSubmit}>
                            <Icon name="circle-check" color="#4022c8" size={24} />
                        </IconButton>
                    </div>
                </>
            )}

            {!isEditing && (
                <>
                    <div className={classes.name}>{name}</div>
                    <div className={classes.type}>{typeLabel}</div>
                    <div className={classes.currency}>{currencyLabel}</div>
                    <div className={classes.iconCell}>
                        <IconButton onClick={() => setIsEditing(true)}>
                            <Icon name="edit" color="rgba(32,32,32, .7)" size={18} />
                        </IconButton>
                    </div>
                </>
            )}

            <div className={classes.iconCell}>
                <IconButton onClick={onRemove}>
                    <Icon name="trash" color="rgba(32,32,32, .7)" size={20} />
                </IconButton>
            </div>

            {!isEditing && error && (
                <div className={classes.errorMessage}>{error}</div>
            )}
        </div>
    )
}

CreateManualProviderConnectionRow.propTypes = {
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
}