import React from "react";
import PropTypes from "prop-types";
import { getProviderConnectionRedirectUrl } from "Services/providersConnections";
import Button from "Components/Button";
import { useQuery } from "@tanstack/react-query";
import { useErrors } from "Components/ErrorsProvider";
import { useTranslation } from "react-i18next";
import { CONNECTOR_SERVICES } from "Utils/constants";
import { htmlDecode } from "Utils/formatters";

export default function ProviderConnectionConnectWithRedirection(props) {
    const { showUnknownError } = useErrors();

    const { t } = useTranslation("common");

    const { providerConnectionId, connectorId, providerName, providerLogoUrl, onCancel, serviceId } = props;
    const service = CONNECTOR_SERVICES[serviceId];

    const { data: redirectUrl, isLoading } = useQuery(["get-provider-connection-redirect-url", { connectorId, providerConnectionId, name: providerName }], () => getProviderConnectionRedirectUrl({ connectorId, providerConnectionId, name: providerName }), {
        onError: error => {
            showUnknownError(error);
            onCancel();
        },
    });

    return (
        <div className="text-center p-4">
            <div className="d-flex align-items-center justify-content-center">
                <img src={providerLogoUrl} alt={`Logo de ${providerName}`} style={{ height: "4rem" }} />
                <h4 className="fw-500 m-0 ms-3">{providerName}</h4>
            </div>

            <div className="my-4">
                {htmlDecode(t("provider-connections.connecting-to", { provider: providerName }))}
            </div>

            <Button primary href={redirectUrl} isBusy={isLoading}>
                {t("provider-connections.start-connection")}
            </Button>

            {service && (
                <div className="d-flex align-items-center justify-content-center mt-4 small">
                    <img alt="" src="/img/icon-padlock-black.svg" height={18} />
                    <span className="ms-2">{t("provider-connections.secure-connection-via")} {" "} <a href={service.url} target="_blank" rel="noreferrer">{service.name}</a></span>
                </div>
            )}
        </div>
    )
}

ProviderConnectionConnectWithRedirection.propTypes = {
    connectorId: PropTypes.string.isRequired,
    providerName: PropTypes.string.isRequired,
    providerLogoUrl: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    providerConnectionId: PropTypes.string,
    serviceId: PropTypes.string,
};
