
import PropTypes from "prop-types";
import React from "react";

const ChevronRight = ({ color, size, ...props }) => (
    <svg viewBox="0 0 24 24" width={size} height={size} {...props}>
        
	<path d="M10.193 7.026a1 1 0 0 0 0 1.41l3.54 3.59-3.54 3.54a1 1 0 0 0 1.42 1.41l4.24-4.24a1.001 1.001 0 0 0 0-1.42l-4.24-4.29a1.001 1.001 0 0 0-1.42 0Z" fill={color}/>

    </svg>
);

export default ChevronRight;

ChevronRight.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
