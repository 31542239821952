import React from "react";
import PropTypes from "prop-types";
import AutocompleteContacts from "Components/AutocompleteContacts";
import { useFormContext, Controller } from 'react-hook-form';

export default function RHFTSelectContact({ name, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <AutocompleteContacts
                    {...field}
                    error={error?.message}
                    {...other}
                />
            )}
        />
    );
}

RHFTSelectContact.propTypes = {
    name: PropTypes.string.isRequired,
};
