
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getIntegrations, sortIntegrations } from "Services/integrations";
import { useMemo, useState, useLayoutEffect } from "react";
import { trackError } from "Utils/errorMonitoring";

const isValidIntegration = (integration) => integration.is_validated && !integration.is_expired

export default function useIntegrations() {
    const { data, isLoading, isFetching } = useQuery(["integrations"], getIntegrations, {
        staleTime: Infinity,
        onError: error => trackError(error),
    });
    const queryClient = useQueryClient();

    // fix flicker when sorting
    const [integrations, setIntegrations] = useState([]);

    useLayoutEffect(() => {
        if (!isLoading && Array.isArray(data)) {
            setIntegrations(data);
        }
    }, [data, isLoading]);

    const { mutate: sort } = useMutation(sortIntegrations, {
        onMutate: (ids) => {
            const newChannelsConnections = ids
                .map(id => integrations.find(item => item.id === id))
                .filter(Boolean)
                .map((item, index) => ({ ...item, order: index + 1 }));

            setIntegrations(newChannelsConnections);
            queryClient.setQueryData(["integrations"], newChannelsConnections);
        }
    });

    const validIntegrations = useMemo(() => integrations.filter(isValidIntegration), [integrations]);
    const channelIntegrations = useMemo(() => validIntegrations.filter(integration => integration.type !== "holded"), [validIntegrations]);

    return {
        isLoading,
        integrations,
        validIntegrations,
        channelIntegrations,
        isFetching,
        sort
    }
}