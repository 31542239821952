import React from "react";
import PropTypes from "prop-types";
import gocardlessImage from "./assets/gocardless.png";
import afterbanksImage from "./assets/afterbanks.png";
import indexaCapitalImage from "./assets/indexa.png";
import tellerImage from "./assets/teller.png";
import wealthReaderImage from "./assets/wealthreader.png";
import Icon from "Components/Icon";
import classes from "./ProviderSelector.module.css";
import clsx from "clsx";
import { formatList } from "Utils/formatters";
import { Trans, useTranslation } from "react-i18next";
import useLocale from "Hooks/useLocale";
import { useUser } from "Components/UserProvider/useUser";
import ProviderConnectorsSupportForm from "./ProviderConnectorsSupportForm";
import { htmlDecode } from "Utils/formatters";

export default function ProviderConnectionConnect(props) {
    const { connectors, onSelect, provider, onCancel } = props;

    const { user } = useUser();
    const { t } = useTranslation("providers");
    const locale = useLocale();

    const CONNECTION_SERVICES = {
        afterbanks_psd2: {
            title: "Open Banking (PSD2)",
            subtitle: `${t("via")} Arcopay`,
            image: afterbanksImage,
            hasTransactionsBalance: false,
        },
        nordigen: {
            title: "Open Banking (PSD2)",
            subtitle: `${t("via")} GoCardLess`,
            image: gocardlessImage,
            hasTransactionsBalance: false,
        },
        afterbanks_v3: {
            title: t("direct-access"),
            subtitle: `${t("via")} Arcopay`,
            image: afterbanksImage,
            hasTransactionsBalance: true,
        },
        indexa_capital: {
            title: t("native-connection"),
            subtitle: `${t("via")} Indexa Capital`,
            image: indexaCapitalImage,
            hasTransactionsBalance: false,
        },
        wealth_reader: {
            title: t("direct-access"),
            subtitle: `${t("via")} Wealth Reader S.L.`,
            image: wealthReaderImage,
            hasTransactionsBalance: true,
        },
        teller: {
            title: t("direct-access"),
            subtitle: `${t("via")} Teller.io`,
            image: tellerImage,
            hasTransactionsBalance: true
        }
    }

    const PRODUCT_TRANSLATIONS = {
        checkings: t("checkings"),
        cards: t("cards"),
        loans: t("loans"),
        investments: t("investments"),
        others: t("others")
    }

    const formatSyncProducts = products => {
        if (!products) return null;

        const translatedProducs = products
            .map(product => PRODUCT_TRANSLATIONS[product])
            .filter(Boolean)
            .map(product => product.toLocaleLowerCase())

        const formattedProducts = formatList(translatedProducs, { limit: 100, locale });

        return `${t("sync-product")} ${formattedProducts}`;
    }

    const expirationString = (connector) => {
        if (connector.update_delta === null) {
            return t("manual-sync")
        } else if (connector.average_expiration !== null) {
            return t("expires-every", { count: connector.average_expiration.toFixed(0) });
        } else {
            return null
        }
    }

    const connectorsFormatted = connectors
        .filter(connector => typeof CONNECTION_SERVICES[connector.service] !== "undefined")
        .sort((a, b) => {
            if (a.is_recommended) return -1;
            if (b.is_recommended) return 1;
            if (a.order === null) return 1;
            if (b.order === null) return -1;

            return a.order - b.order;
        })
        .map(connector => {
            const connection = CONNECTION_SERVICES[connector.service];
            return {
                ...connection,
                id: connector.id,
                isRecommended: connector.is_recommended,
                syncProducts: formatSyncProducts(connector.available_products),
                expire: expirationString(connector),
                isBusiness: connector.is_business,
                connector
            }
        })

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            {user.is_support && (
                <div className="w-100">
                    <ProviderConnectorsSupportForm
                        provider={provider}
                        onCancel={onCancel}
                    />
                </div>
            )}

            {!user.is_support && (
                <div className="text-center">
                    <img
                        src={provider.logo_url}
                        className={clsx(classes.providerLogo, "mx-auto")}
                        alt={provider.name}
                    />

                    <h4>
                        {t("connect-with")}
                        <br /> {provider.name}</h4>
                    <div className="text-muted">
                        {htmlDecode(t("what-connection-type-do-you-want-to-use-to-connect-with", { name: provider.name }))}
                    </div>
                </div>
            )}

            <div className="mt-4 w-100 p-1 scrollbar" style={{ maxHeight: "450px", overflowY: "auto" }}>
                <div className="d-grid gap-2">
                    {connectorsFormatted.length === 0 && (
                        <div className="text-center py-5 text-muted">
                            {t("no-providers-connection-available")}
                        </div>
                    )}

                    {connectorsFormatted.map(item => {
                        return (
                            <div className={classes.connector} key={item.id}>
                                <button className={classes.connectorButton} type="button" onClick={() => onSelect(item.connector)}>
                                    <div className={classes.badges}>
                                        {item.isBusiness && <span className={clsx(classes.connectorBadge, classes.primaryBadge)}>{t("business")}</span>}
                                        {item.isRecommended && <span className={classes.connectorBadge}>{t("recommended")}</span>}
                                    </div>
                                    <div className={classes.connectorImageContainer}>
                                        <img src={item.image} className={classes.connectorImage} alt={item.title} />
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className={classes.connectorTitle}>{item.title}</div>
                                        <div className="d-grid gap-2">
                                            <div className="text-muted pb-2">{item.subtitle}</div>
                                            {item.syncProducts && (
                                                <div className={classes.connectorInfoItem}>
                                                    <Icon name="bank" size={20} color="currentColor" className="flex-shrink-0" />
                                                    <span>{item.syncProducts}</span>
                                                </div>
                                            )}
                                            {item.expire && (
                                                <div className={classes.connectorInfoItem}>
                                                    <Icon name="time" size={20} color="currentColor" className="flex-shrink-0" />
                                                    <span>{item.expire}</span>
                                                </div>
                                            )}
                                            {item.hasTransactionsBalance && (
                                                <div className={classes.connectorInfoItem}>
                                                    <Icon name="square-check" size={20} color="currentColor" className="flex-shrink-0" />
                                                    <span>
                                                        <Trans t={t} i18nKey="transactions-with-balance">
                                                            Transacciones <strong style={{ fontWeight: 600 }}>CON</strong> saldo
                                                        </Trans>
                                                    </span>
                                                </div>
                                            )}
                                            {!item.hasTransactionsBalance && (
                                                <div className={classes.connectorInfoItem}>
                                                    <Icon name="square-times" size={20} color="currentColor" className="flex-shrink-0" />
                                                    <span>
                                                        <Trans t={t} i18nKey="transactions-without-balance">
                                                            Transacciones <strong style={{ fontWeight: 600 }}>SIN</strong> saldo
                                                        </Trans>
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.arrow}>
                                        <Icon name="arrow-right" size={40} gradient="secondary" />
                                    </div>
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="mt-4 opacity-50 text-center text-small text-muted d-none">
                {t("remember-you-can-always-modify-the-connection-later-from-your-bank-settings")}
            </div>
        </div>
    );
}

ProviderConnectionConnect.propTypes = {
    connectors: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    provider: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
} 