import React, { createContext, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "Utils/constants";
import Loading from "Components/Loading";
import useUTMParams from "Hooks/useUTMParams";
import storage from 'Utils/storage';
import Reactivate from "./Reactivate";
import { createUser } from "Services/user";
import { useTracking } from "Components/TrackingProvider";
import { useUser } from "Components/UserProvider/useUser";
import { useErrors } from "Components/ErrorsProvider";
import { getCookie } from "Utils/cookies";
import { useTranslation } from "react-i18next";
import { COOKIES } from "Utils/constants";
import useAffiliateCode from "Hooks/useAffiliateCode";

const AuthContext = createContext(null);

function getTimezone() {
    if (typeof Intl === "undefined") return null;

    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const AuthProvider = ({ children }) => {
    const { login } = useUser();
    const { showUnknownError } = useErrors();
    const navigate = useNavigate();
    const [resetPasswordEmail, setResetPasswordEmail] = useState("");
    const [isUserDeleted, setIsUserDeleted] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const utmParams = useUTMParams();
    const affCode = useAffiliateCode();
    const showGooglePrompt = useRef(true);
    const { track } = useTracking();

    const { i18n } = useTranslation();
    const clearResetPassword = () => setResetPasswordEmail("");

    const requireResetPassword = (email) => {
        setResetPasswordEmail(email);
        navigate(ROUTES.RESET_PASSWORD);
    }

    const handleReactivate = (bodyResponse) => {
        const token = storage.getItem('token');
        login(token, bodyResponse)
        setIsUserDeleted(false);
    }

    const loginUser = async (token) => {
        storage.setItem('token', token);
        setIsProcessing(true);

        const firstContact = getCookie(COOKIES.FIRST_CONTACT) || "";
        const language = i18n.language;

        try {
            const timezone = getTimezone();
            const response = await createUser({ ...utmParams, first_contact: firstContact, language, timezone, aff_code: affCode });
            if (!response) return;

            if (response.is_new_user) {
                track('user_created');
            }

            if (response.is_deleted) {
                setIsUserDeleted(true);
            } else {
                login(token, response)
            }
        } catch (error) {
            showUnknownError(error)
        }

        setIsProcessing(false);
    }

    const renderLoginWithGoogle = (buttonContainer, options = {}) => {
        if (window.google === undefined) return;

        const userAgent = navigator.userAgent

        if (userAgent.match(/Instagram/i)) {
            return;
        }

        window.google.accounts.id.initialize({
            client_id: "670097247872-61plaam3qr49ucn8maifudhq5kdi474u.apps.googleusercontent.com",
            auto_select: false,
            use_fedcm_for_prompt: true, // https://developers.google.com/identity/gsi/web/guides/fedcm-migration 

            callback: response => {
                if (response.credential) {
                    loginUser(response.credential);
                }
            }
        });

        const width = buttonContainer.offsetWidth;

        window.google.accounts.id.renderButton(buttonContainer, {
            theme: "filled_blue",
            size: "large",
            shape: "pill",
            type: "standard",
            locale: i18n.language.toLowerCase(),
            width,
            ...options
        });

        if (showGooglePrompt.current) {
            window.google.accounts.id.prompt();
            showGooglePrompt.current = false;
        }
    };


    return (
        <AuthContext.Provider value={{
            renderLoginWithGoogle,
            loginUser,
            requireResetPassword,
            resetPasswordEmail,
            clearResetPassword
        }}>
            {isProcessing ?
                (<div className="mt-5"><Loading message={"Cargando..."} /></div>)
                : isUserDeleted
                    ? (<Reactivate onReactivate={handleReactivate} />)
                    : children
            }

        </AuthContext.Provider>
    );
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export const useAuth = () => {
    const authContext = useContext(AuthContext);

    if (!authContext) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return authContext;
}

export default AuthProvider;