
import { useContext } from "react";
import SyncContext from "Components/SyncProvider/SyncContext";

export default function useSynchronizeProviderConnection() {
    const context = useContext(SyncContext);

    if (!context) {
        throw new Error("useSynchronizeProviderConnection must be used within a SyncProvider");
    }

    return context
}
