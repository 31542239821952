import axiosInstance from "Utils/axiosInstance";
import { mapDtoToIntegration, sortItems } from "Utils/mappers";

export async function getIntegrations() {
    const response = await axiosInstance.get("/integrations");

    if (!response?.data) return [];

    return response.data.sort(sortItems).map(mapDtoToIntegration);
}

export async function editIntegration({ id, name }) {
    const response = await axiosInstance.put(`/integrations/${id}`, { name });

    if (!response?.data) return [];

    return mapDtoToIntegration(response.data);
}

export async function deleteIntegration(channelConnectionId) {
    await axiosInstance.delete(`/integrations/${channelConnectionId}`);
}

export async function associateIntegration(channelConnectionId, reportsConnections) {
    await axiosInstance.post('/reports/associate', { reports_connections_ids: reportsConnections.map(item => item.id), channel_connection_id: channelConnectionId });
}

export async function sortIntegrations(newOrder) {
    await axiosInstance.post('/integrations/sort', newOrder);
}

export async function sendTelegramInstructions(channelConnectionId) {
    await axiosInstance.post(`/integrations/${channelConnectionId}/instructions`);
}

export async function sendWhatsappInstructions(channelConnectionId) {
    await axiosInstance.post(`/integrations/${channelConnectionId}/instructions`);
}

export async function createIntegrationEmail(email, otp, integrationId) {
    await axiosInstance.post('/integrations/email', { email, otp, integration_id: integrationId });
}


function handleResendError(error) {
    if (error.response?.status === 400 && error.response.data.description === "requires_verification") {
        return
    }

    throw error
}

export async function resendIntegrationEmail(email) {
    try {
        await axiosInstance.post('/integrations/email', { email, otp: null, integration_id: null });
    } catch (error) {
        handleResendError(error)
    }
}

export async function resendIntegrationWhatsapp(number) {
    const normalizedPhone = number.replace(/\s/g, '');

    try {
        await axiosInstance.post('/integrations/whatsapp', { number: normalizedPhone, otp: null, integration_id: null });
    } catch (error) {
        handleResendError(error)
    }
}

export async function resendIntegrationSMS(number) {
    const normalizedPhone = number.replace(/\s/g, '');

    try {
        await axiosInstance.post('/integrations/sms', { number: normalizedPhone, otp: null, integration_id: null });
    } catch (error) {
        handleResendError(error)
    }
}

export async function createIntegrationSMS(number, otp, integrationId) {
    const normalizedPhone = number.replace(/\s/g, '');

    await axiosInstance.post('/integrations/sms', { number: normalizedPhone, otp, integration_id: integrationId });
}

export async function createIntegrationWhatsApp(number, otp, integrationId) {
    const normalizedPhone = number.replace(/\s/g, '');

    await axiosInstance.post('/integrations/whatsapp', { number: normalizedPhone, otp, integration_id: integrationId });
}

export async function createIntegrationTelegram(otp, integrationId) {
    await axiosInstance.post('/integrations/telegram', { otp, integration_id: integrationId });
}

export async function createIntegrationSlack(channelId, integrationId) {
    await axiosInstance.post('/integrations/slack', { channel_id: channelId, integration_id: integrationId });
}

export async function createIntegrationHolded(token) {
    await axiosInstance.post('/integrations/holded', { token });
}

export async function synchronizeIntegration(integrationId) {
    await axiosInstance.post(`/integrations/${integrationId}/synchronize`);
}