import { ListSubheader, MenuItem, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef, useMemo } from "react";
import { styled } from '@mui/material/styles';
import useProvidersConnections from "Hooks/useProvidersConnections";
import Icon from "Components/Icon";
import classes from "./SelectProduct.module.css";
import { useTranslation } from "react-i18next";

const TextFieldStyled = styled(TextField)({
    '& *': {
        fontFamily: 'var(--f-family-primary)!important'
    },
    '& div input': {
        background: 'white'
    },
    '& div.Mui-focused input': {
        color: '#2E16BA'
    },
    '& label.Mui-focused': {
        color: '#2E16BA'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#2E16BA',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DDDDDD',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#2E16BA',
        },
    }
});

const SelectProduct = forwardRef(function SelectProduct(props, ref) {

    const { isLoading, visibleProvidersConnections = [] } = useProvidersConnections();

    const { t } = useTranslation("common");

    const options = useMemo(() => {

        const none = {
            isNone: true,
            isHeader: false,
            id: "",
            label: t("none"),
        }

        const options = visibleProvidersConnections.flatMap(item => {
            const products = item.products.map(product => ({
                isHeader: false,
                isNone: false,
                id: product.id,
                label: product.name,
                provider: {
                    icon: <img src={item.logo_url} alt="" className={classes.logo} />,
                    name: item.name
                },
                icon: <Icon name="chevron-right" size={20} color="rgba(32,32,32, .5)" />
            }))

            const provider = {
                isHeader: true,
                isNone: false,
                id: item.id,
                label: item.name,
                icon: <img src={item.logo_url} alt="" className={classes.logo} />,
            }

            return [provider, ...products]
        })

        return [none, ...options]
    }, [visibleProvidersConnections])

    if (isLoading) {
        return (
            <div style={{ height: 56 }} />
        )
    }

    return (
        <TextFieldStyled
            ref={ref}
            select
            fullWidth
            {...props}
        >
            {options.map(item => {
                if (item.isNone) {
                    return (
                        <MenuItem key={item.id} value={item.id}>
                            {"-"} {item.label} {"-"}
                        </MenuItem>
                    )
                }

                if (item.isHeader) {
                    return (
                        <ListSubheader key={item.id} className={classes.groupTitle}>
                            <div className={classes.row}>
                                <div className={classes.cellIcon}>
                                    {item.icon}
                                </div>
                                <div>{item.label}</div>
                            </div>
                        </ListSubheader>
                    )
                }

                return (
                    <MenuItem key={item.id} value={item.id}>
                        <div className={classes.row}>
                            <div className={classes.cellIcon}>
                                {item.icon}
                            </div>
                            <div>
                                {item.label}
                            </div>
                        </div>

                        <div className={classes.inputValue}>
                            <div className={classes.cellIcon}>
                                {item.provider.icon}
                            </div>
                            <div className={classes.ellipsis}>
                                {item.provider.name}
                            </div>
                            <div className={classes.cellIcon}>
                                {item.icon}
                            </div>
                            <div className={classes.ellipsis}>
                                {item.label}
                            </div>
                        </div>
                    </MenuItem>
                )
            })}
        </TextFieldStyled >
    )
})

SelectProduct.propTypes = {
    className: PropTypes.string,
};

export default SelectProduct;