import { Navigate, createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import React from 'react';
import SearchProvider from './Components/SearchProvider';
import { ROUTES } from './Utils/constants';
import AuthProvider from './Components/AuthProvider';
import { EventDispatcherProvider } from 'Components/EventDispatcherContext';
import CallbackGoogle from "./Pages/Callbacks/Google";
import TrackingProvider from 'Components/TrackingProvider';
import ErrorsProvider from 'Components/ErrorsProvider';
import UserProvider from 'Components/UserProvider';
import AppLayout from 'Components/AppLayout';
import GuardPublic from 'Components/GuardPublic';
import GuardPrivate from 'Components/GuardPrivate';
import GuardOnboarding from 'Components/GuardOnboarding';
import GuardWorkspace from 'Components/GuardWorkspace';
import Loading from 'Components/Loading';
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryRoute, { ErrorFallback } from 'Components/ErrorBoundary';
import { sessionStorage } from 'Utils/storage';
import SnackbarProvider from 'Components/SnackbarProvider';
import { SlotsProvider } from 'nano-slots'
import useCheckLastAppVersion from 'Hooks/useCheckLastAppVersion';
import GlobalFloatingProvider from 'Components/GlobalFloatingProvider';

const ProvidersRoot = () => (
    <EventDispatcherProvider>
        <GlobalFloatingProvider>
            <ErrorsProvider>
                <SnackbarProvider>
                    <UserProvider>
                        <TrackingProvider>
                            <AuthProvider>
                                <SearchProvider>
                                    <SlotsProvider>
                                        <Outlet />
                                    </SlotsProvider>
                                </SearchProvider>
                            </AuthProvider>
                        </TrackingProvider>
                    </UserProvider>
                </SnackbarProvider>
            </ErrorsProvider>
        </GlobalFloatingProvider>
    </EventDispatcherProvider>
);

async function retry(componentImport) {
    const refreshed = sessionStorage.getItem('retry-refreshed') === 'true'

    try {
        const module = await componentImport()
        sessionStorage.removeItem('retry-refreshed')
        return { Component: module.default }
    } catch (error) {
        if (!refreshed) {
            sessionStorage.setItem('retry-refreshed', 'true')
            window.location.reload()
            return null
        }

        throw error
    }
}

const router = createBrowserRouter([{
    element: <ProvidersRoot />,
    errorElement: <ErrorBoundaryRoute />,
    children: [{
        // public routes
        element: <GuardPublic />,
        children: [{
            path: ROUTES.signIn,
            lazy: () => retry(() => import("./Pages/AuthSignIn"))
        }, {
            path: ROUTES.signUp,
            lazy: () => retry(() => import("./Pages/AuthSignUp"))
        }, {
            path: ROUTES.signUpEmailConfirm,
            lazy: () => retry(() => import("./Pages/AuthSignUpEmailConfirm"))
        }, {
            path: ROUTES.magicCode,
            lazy: () => retry(() => import("./Pages/AuthMagicCode"))
        }, {
            path: ROUTES.forgotPassword,
            lazy: () => retry(() => import("./Pages/AuthForgotPassword"))
        }, {
            path: ROUTES.guestSignUp,
            lazy: () => retry(() => import("./Pages/AuthGuestSignUp"))
        }, {
            path: ROUTES.callbackGoogle,
            element: <CallbackGoogle />,
        }, {
            path: "*",
            element: <Navigate to={ROUTES.signIn} replace />,
        }]
    }, {
        // private routes
        element: <GuardPrivate />,
        children: [{
            element: <AppLayout />,
            children: [{
                path: ROUTES.reports,
                lazy: () => retry(() => import("./Pages/Reports/ReportsConnections"))
            }, {
                path: ROUTES.alerts,
                lazy: () => retry(() => import("./Pages/Alerts/AlertsConnections"))
            }, {
                path: ROUTES.automations,
                lazy: () => retry(() => import("./Pages/Automations/Automations"))
            }, {
                path: ROUTES.dashboards,
                lazy: () => retry(() => import("./Pages/Dashboards/DashboardsRoot")),
                children: [{
                    path: `${ROUTES.dashboards}:dashboardId`,
                    lazy: () => retry(() => import("./Pages/Dashboards/Dashboard")),
                }]
            }, {
                path: ROUTES.categories,
                lazy: () => retry(() => import("./Pages/Categories/Categories"))
            }, {
                path: ROUTES.providers,
                lazy: () => retry(() => import("./Pages/Providers/ProviderConnections"))
            }, {
                path: ROUTES.integrations,
                lazy: () => retry(() => import("./Pages/Integrations/Integrations"))
            }, {
                path: ROUTES.settings,
                lazy: () => retry(() => import("./Pages/Settings/Settings"))
            }, {
                path: ROUTES.search,
                lazy: () => retry(() => import("./Pages/Search/Search"))
            }, {
                path: ROUTES.invoices,
                lazy: () => retry(() => import("./Pages/Invoices/InvoicesRoot")),
                children: [{
                    path: ROUTES.invoicesCalendar,
                    lazy: () => retry(() => import("./Pages/Invoices/InvoicesCalendar"))
                }, {
                    path: ROUTES.invoices,
                    lazy: () => retry(() => import("./Pages/Invoices/Invoices"))
                }]
            }, {
                path: ROUTES.contacts,
                lazy: () => retry(() => import("./Pages/Contacts/Contacts"))
            }, {
                path: ROUTES.profile,
                lazy: () => retry(() => import("./Pages/Profile/Profile"))
            }, {
                path: ROUTES.forecasts,
                lazy: () => retry(() => import("./Pages/Forecasts/ForecastsRoot")),
                children: [{
                    path: `${ROUTES.forecasts}:forecastId`,
                    lazy: () => retry(() => import("./Pages/Forecasts/Forecast"))
                }]
            }, {
                path: ROUTES.affiliates,
                lazy: () => retry(() => import("./Pages/Affiliates/Affiliates"))
            }],
        }, {
            path: "*",
            element: <Navigate to={ROUTES.dashboards} />,
        }]
    }, {
        // onboarding routes
        element: <GuardOnboarding />,
        children: [{
            path: ROUTES.onboarding,
            lazy: () => retry(() => import("./Pages/Onboarding")),
        }, {
            path: "*",
            element: <Navigate to={ROUTES.onboarding} />,
        }]
    }, {
        // select workspace
        element: <GuardWorkspace />,
        children: [{
            path: ROUTES.selectWorkspace,
            lazy: () => retry(() => import("./Pages/SelectWorkspace")),
        }, {
            path: "*",
            element: <Navigate to={ROUTES.selectWorkspace} />,
        }]
    }]
}])

export default function App() {
    useCheckLastAppVersion()

    return (
        <ErrorBoundary fallback={ErrorFallback}>
            <RouterProvider router={router} fallbackElement={<div className='py-4'><Loading /></div>} />
        </ErrorBoundary>
    )
}