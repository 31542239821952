import { useEffect, useId, useMemo } from "react";
import { useGlobalFloating } from "Components/GlobalFloatingProvider";

export default function useDialogProps(props = {}) {
    const { open, keepSupportChat } = props;
    const id = useId();
    const { addFloatingKey, removeFloatingKey } = useGlobalFloating();

    useEffect(() => {
        if (keepSupportChat) return;

        if (open) {
            addFloatingKey(id);

            return () => {
                removeFloatingKey(id);
            }
        }
    }, [open, keepSupportChat]);

    return useMemo(() => {
        const { keepSupportChat, ...rest } = props;

        return rest;
    }, [props]);
}